/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://contents.premium.naver.com/efocus/efocusnews/contents/240723135902002ja"
  }, "티메파크 전산 지연 사태")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://imnews.imbc.com/replay/2024/nwtoday/article/6620344_36523.html"
  }, "김범수 카카오 의장 구속")), "\n", React.createElement(_components.li, null, "Crowdstrike가 발생시킨 Windows 장애", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://uk.news.yahoo.com/live/microsoft-outage-it-crowdstrike-status-flights-grounded-latest-072117660.html"
  }, "주요 장면들(영문)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcommunity.microsoft.com/t5/azure-compute-blog/recovery-options-for-azure-virtual-machines-vm-affected-by/ba-p/4196798"
  }, "Azure VM 관련(영문)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newsletter.pragmaticengineer.com/p/the-biggest-ever-global-outage-lessons"
  }, "이번 장애에서 배운 점(영문)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2024/07/24/crowdstrike-offers-a-10-apology-gift-card-to-say-sorry-for-outage/"
  }, "Crowdstrike의 $10 보상안(영문)")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://frame.work/"
  }, "Framework Laptop")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
